@tailwind base;
@tailwind components;
@tailwind utilities;

.compartir {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: white;
  text-align: center;
  vertical-align: center;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 10rem;
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.mejorar {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: black;
  text-align: center;
  vertical-align: center;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 10rem;
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}



.imagen {
  opacity: 1;
}

.bg-image {
  opacity: 0.7;
}

.bg-img-title {
  position: absolute;
  top: 10px;
  left: 20px;
  color: white;
  font-family: 'Asap', sans-serif;
  font-style: italic;
  font-size: 40px;
}

.bg-img-title2 {
  position: absolute;
  top: 400px;
  left: 20px;
  color: white;
  font-family: 'Asap', sans-serif;
  font-style: italic;
  font-size: 40px;
}

.showcase {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid black;
  border-radius: 25px;
  width: 100%;
  height: 100vh;
  /* background-image: url('../utils/images/acercade.jpg'); */
}

.bg-img-bloque-uno {
  color: black;
  font-family: 'Asap', sans-serif;
  font-style: italic;
  font-size: 30px;
  align-items: flex-end;
}

.bg-img-bloque-dos {
  top: 240px;
  left: 100px;
  position: absolute;
  color: white;
  font-family: 'Asap', sans-serif;
  font-style: italic;
  font-size: 30px;
}

.grid-container {
display: grid;
gap: 10px;
background-color:white;
/* background-image: url('../utils/images/acercade.jpg'); */
padding: 10px;
color: gray;
font-size: 60px;
border-radius: 25px;
font-size: 20px;
font-family: 'Asap', sans-serif;
}


.grid-container-uno {
display: grid;
gap: 10px;
background-color:#fefefe;
/* background-image: url('../utils/images/acercade.jpg'); */
padding: 10px;
color: black;
border-radius: 25px;
font-size: 20px;
font-family: 'Asap', sans-serif;
}

.grid-container-dos {
display: grid;
gap: 10px;
background-color: white;
/* background-image: url('../utils/images/acercade.jpg'); */
padding: 10px;
color: black;
border-radius: 0;
font-size: 20px;
font-family: 'Asap', sans-serif;
}

.div-cuadro {
border-radius: 0;
color: white;
text-align: justify;
background-color: #333a40;
}

.div-cuadro-uno {
border-radius: 0;
color: black;
text-align: justify;
background-color: white;
}

.div-cuadro-contacto {
color: black;
background-color: white;
}
.div-label-cuadro {
text-align: center;
font-size: 20px;
color: black;
font-weight: 900;
padding-bottom: 2rem;

}

.div-cuadro-icono {
display: inline-block;
font-weight: 600;
line-height: 1.5;
/* color: #22AE5C; */
color: red;
text-align: center;
vertical-align: center;
cursor: pointer;
user-select: none;
background-color: transparent;
border: 1px solid transparent;
padding: 0.75rem 1.5rem;
font-size: 5rem;
border-radius: 0.475rem;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.titulo-h2 {
color: black;
font-size: 50px;
font-weight: bold;
text-align: center;
font-family: 'Asap', sans-serif;
padding-bottom: 5rem;
}


.contenido-ul {
color: black;
font-size: 20px;
text-align: center;
margin-bottom: 3.125rem;
}

.icono-encabezado-uno {
color:red;
padding-right: 1rem;
}


.container-club-uno {
display: grid;
gap: 10px;
background-color: white;
background-image: url('../utils/images/clubes.jpg');
padding: 10px;
color: white;
border-radius: 0;
height: 60vh;

}

.container-club-dos {
display: grid;
gap: 10px;
background-color: white;
padding: 10px;
color: black;
border-radius: 0;
font-size: 20px;
font-family: 'Asap', sans-serif;  
}

.div-cuadro-club {
display: inline-block;
font-weight: 600;
line-height: 1.5;
/* color: #22AE5C; */
color: red;
text-align: center;
vertical-align: center;
cursor: pointer;
user-select: none;
background-color: transparent;
border: 1px solid transparent;
padding: 0.75rem 1.5rem;
font-size: 30px;
border-radius: 0.475rem;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.icono-planes-socios-ok {
color:white;
text-align: start;
font-size: 2rem;
}
.tableplan {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.icono-planes-socios-ok-gratis {
color: #36b585;
text-align: start;
font-size: 2rem;
//margin-right: 1rem;
float: left;
}

.icono-planes-socios-cancel {
color:red;
text-align: start;
font-size: 2rem;
}

.text-planes-socios{
text-align: start;
font-size: 15px;
font-weight: 600;
}

.text-titulo-pro{
text-align: center;
font-size: 25px;
font-weight: 500;
}

.text-titulo-gratis{
text-align: center;
font-size: 25px;
font-weight: 500;
color: gray;
}

.container-plan-pro {
background-color: #36b585;
color: white;
border-radius: 10px;
}

.card-plain {
background-color: transparent;
box-shadow: none;
border: 0;
}
