
.cardEvent{
    border-radius: 10px!important;
    width: 30em;
    height: 220px;
}

.backgroundCard{
    background: rgb(34, 173, 92);
    border-radius: 10px!important;
    border : 2px;
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.75);
    width: 30em;
    background-position: 50%;

    background-size: cover;
}

.backgroundCard:hover {
    cursor: pointer;
}

.cardtitle{
    font-weight: 700;
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 0;
    line-height: 1.4em;
    padding: 0;

    font-weight: bold;
    color: white;
    text-align: center;
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    2px 2px 0 #000,
    -2px 2px 0 #000,
    2px -2px 0 #000,
    -2px -2px 0 #000,
    0px 2px 0 #000,
    0px -2px 0 #000,
    -2px 0px 0 #000,
    2px 0px 0 #000,
    1px 2px 0 #000,
    -1px 2px 0 #000,
    1px -2px 0 #000,
    -1px -2px 0 #000,
    2px 1px 0 #000,
    -2px 1px 0 #000,
    2px -1px 0 #000,
    -2px -1px 0 #000;
}

.cardtitlebox{
    height: 25%;
}

.streams {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    border-radius: 15px;
    background: rgba(0, 0, 0, .5);
    z-index: 20;
    line-height: 1.1;
    font-size: 12px;
    padding: 5px 12px;
    text-transform: uppercase;
    color: #fff;
    z-index: 0;

}

.time {
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 15px;
    background: #f8f9fa!important;
    font-size: 9px;
    padding: 5px 12px;
    color: black;
    z-index: 0;
  
}

.club {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: white;
    border-radius: 15px;
    background: rgba(0, 0, 0, .5);
    z-index: 20;
    line-height: 1.1;
    font-size: 12px;
    padding: 5px 12px;
    text-transform: uppercase;
    color: #fff;
    z-index: 0;
  
}