@tailwind base;
@tailwind components;
@tailwind utilities;

.video-container {
  border-radius: 10px;
  width: 100%;

}

.flexboxvideo {
  display: flex;
  justify-content: center;

}

.buttonlikedislike {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #181C32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.imgnew{
  display: none;
}

@media screen and (min-width: 769px) {
  .imgnew{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    height: 50px;
    width: 50px;
  
  }
}

.labelviews{
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #181C32;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.buttonprivate:hover {
  color: black;
  border-color: lightgray;
  background-color: lightgray;
}


.buttonlike:hover {
  color: #50CD89;
  border-color: #E8FFF3;
  background-color: #E8FFF3 !important;
}

.buttonlikedislikeicon {
  flex-shrink: 0;
  line-height: 0;
  margin-right: 0.5rem;
}
.rs-picker-select-menu{
  z-index: 9999 !important;
}
.buttonMinuteContainer{
  padding-top: 20px;
  padding-bottom: 5px;
}

.buttonMinute{
  padding-bottom: 6px;
  padding-top: 2px;
  background-color: lightgray;
  color: black;
  width: 80px;
  height: 30px;
  border-radius: 25%;
}

.bottonMinuteIcon{
  flex-shrink: 0;
  line-height: 0;
}

.bottonLimit{
  height: 36px;
  width: 36px;
  background-color: orange;
  color:white
}

.blockcontrol::-webkit-media-controls {
  display: none;
}

.buttonHL{
  width: 150px!important;
}

.buttonHLModal{
  width: 80px!important;
}

.spanHL{
  right: 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #181C32;
  text-align: end;
}

.spanRed{
  left: 0;
  text-align: start;
  color: red;
  font-size: 0.8rem;
}


.rs-toggle-presentation{
  background-color: #50CD89!important;
}
.rs-toggle-checked .rs-toggle-presentation{
  background-color: red!important;
}

.whisperShare{
  height: 12em;
  width: 40em;
  color: #000;
  background-color: #fff;
  padding: 10;
  border-radius: 10px;
  position: absolute;
  border: 1px solid #ddd;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.6);
  z-index: 9;
}
.shareTitle{
  font-size: 1.2rem;
  font-weight: 500;
  color: #181C32;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.rowIconShare{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.rowLink{
  padding-left: 40px;
  padding-right: 40px;
}

.colIconShare{
  justify-content: center;
  text-align: center;
}

.share-button{
  cursor: pointer;
}

.BtnPrevisualizacion{
  display: flex;
  justify-content: end;
  margin-top: 1em;
}

.btnTagUser {
  flex-shrink: 0;
  line-height: 0;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 940px) {
  .BtnPrevisualizacion {
    margin-top: 4em;
  }
  .whisperShare{
    height: 12em;
    width: 60vw;
    left: 0!important;
    padding: 0;
  }
  .shareTitle{
    font-size: 1.2rem;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .inputlink{
   width: 85%; 
  }
  .copylink{
    width: 8em;
  }
  .rowLink{
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width:457px){
  .whisperShare{
    height: 17em;
    width: 60vw;

    padding-left: 10px;
    padding-right: 10px;

  }
  .shareTitle{
    font-size: 1.2rem;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .inputlink{
   width: 8em; 
  }
  .copylink{
    width: 3em;
  }
  .rowLink{
    padding-left: 5px;
    padding-right: 5px;
  }
}



