.player {
  display: block;
  overflow-x: hidden;
  background-color: transparent !important;
  border-radius: 15px;
  border: none;
}
.video-js .vjs-tech {
  top: -0.21em !important;
  background-color: transparent !important;
  border: none !important;
  height: auto !important;
  overflow-x: hidden !important;
  border-radius: 15px !important;
}
.chapters {
  height: 100%;
  background-color: #ff342f;
  backface-visibility: hidden;
}
.vjs-button > .vjs-icon-placeholder {
  margin-top: 12px;
}
.video-js .vjs-control-bar {
  padding: 0 20px;
  font-size: 1.2rem;
}
.come-from-left {
  animation: comeFromLeft 0.3s;
  transition: ease;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
}
@keyframes comeFromLeft {
  from {
    transform: translateX(-10rem);
    width: 0;
    opacity: 0;
  }
  to {
    transform: translateX(0);
    width: 30%;
    opacity: 1;
  }
}

.go-to-left {
  animation: goToLeft 0.3s;
  transition: ease;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
}

@keyframes goToLeft {
  from {
    transform: translateX(0);
    width: 30%;
  }
  to {
    transform: translateX(-10rem);
    width: 0;
    opacity: 0;
  }
}
/* .chapters-button .vjs-icon-placeholder::before{
    content:"🌐";
    font-size:inherit;
    margin-top:10px;
} */
.rewindIcon .vjs-icon-placeholder {
  height: 30px;
  width: 30px;
  background-image: url("../utils/images/rewind10Icon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -0.2rem;
  left: 0;
  cursor: pointer;
  z-index: 1;
}
.rewindIcon:hover {
  filter: invert(0.4) sepia(1) saturate(4695) hue-rotate(365deg);
}
.fast-forward-icon .vjs-icon-placeholder {
  height: 30px;
  width: 30px;
  background-image: url("../utils/images/forward10Icon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -0.2rem;
  left: 0;
  cursor: pointer;
  z-index: 1;
}
.fast-forward-icon:hover {
  filter: invert(0.4) sepia(1) saturate(4695) hue-rotate(365deg);
}

.fast-forward-icon:hover {
  cursor: pointer;
}
.rewindIcon:hover {
  cursor: pointer;
}
.video-js {
  height: 100% !important;
  width: 100%;
  transition: width 0.3s;
  backface-visibility: hidden;
}
.vjs-mattrix.video-js {
  color: #cbcbcb;
}
.vjs-matrix .vjs-big-play-button {
  border-color: #ff342f;
  transition: ease;
  border-radius: 20px;
}
.vjs-matrix .vjs-play-control {
  color: #cbcbcb;
}
.vjs-matrix .vjs-play-control:hover {
  color: #fff;
}
.vjs-matrix .vjs-volume-level,
.vjs-matrix .vjs-play-progress,
.vjs-matrix .vjs-slider-bar {
  background-color: #ff342f !important;
}

div.vjs-progress-control.vjs-control {
  padding: 1em 1.7em 1em 1.7em;
  display: border-box;
  position: absolute;
  width: 100%;
  height: 1em;
  top: 0.5em;
  left: 0;
  right: 0;
}
div.vjs-remaining-time.vjs-time-control.vjs-control {
  margin-left: auto;
}
div.vjs-progress-holder.v js-slider.vjs-slider-horizontal {
  margin: 0;
}
div.vjs-control-bar {
  height: 3.1rem;
  padding: 0 20px;
}
.skip-teaser-button {
  position: absolute;
  height: 0 !important;
  bottom: 40vh;
  left: 60px;
}
.skip-teaser-button::before {
  content: "Saltar anuncio";
  white-space: nowrap;
  background-color: "rgba(0,0,0,0.3)";
  padding: 15px;
  border-radius: 6px;
  border: 3px solid #fff;
}

.skip-ads-button {
  position: absolute;
  height: 0 !important;
  bottom: -30em;
  left: 43em;
}
.skip-ads-button:hover {
  cursor: pointer;
  color: #ff342f;
}
.skip-ads-button::before {
  content: "Saltar anuncio";
  white-space: nowrap;
  background-color: "#ffff";
  font-size: 16px;
  padding: 12px;
  border-radius: 10px;
  border: 3px solid #fff;
}

.next-button-on-Control .vjs-icon-placeholder::before {
  content: "⏭️";
  white-space: nowrap;
}

@media (max-width: 1108px) {
  .next-button-side::before {
    position: absolute;
    bottom: 10em;
    left: 60em;
    width: 100px;
    font-weight: bold;
    content: "Siguiente video";
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.3);
    color: #cbcbcb;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ff342f;
  }
}
@media (min-width: 1110px) and (max-width: 2240px) {
  .next-button-side::before {
    position: absolute;
    bottom: 10em;
    left: 80em;
    width: 100px;
    font-weight: bold;
    content: "Siguiente video";
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.3);
    color: #cbcbcb;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ff342f;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .next-button-side::before {
    position: absolute;
    bottom: 10em;
    left: 60em;
    width: 100px;
    font-weight: bold;
    content: "Siguiente video";
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.3);
    color: #cbcbcb;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ff342f;
  }
}
@media (max-width: 540px) {
  .next-button-side::before {
    position: absolute;
    bottom: 10em;
    left: 40em;
    width: 100px;
    font-weight: bold;
    content: "Siguiente video";
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.3);
    color: #cbcbcb;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ff342f;
  }
}
@media (max-width: 361px) {
  .next-button-side::before {
    position: absolute;
    bottom: 10em;
    left: 20em;
    width: 80px;
    font-weight: bold;
    content: "Siguiente";
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.3);
    color: #cbcbcb;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ff342f;
  }
}
@media (max-width: 375px) {
  .next-button-side::before {
    position: absolute;
    bottom: 10em;
    left: 25em;
    width: 80px;
    font-weight: bold;
    content: "Siguiente";
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.3);
    color: #cbcbcb;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ff342f;
  }
}
@media (max-width: 420px) {
  .next-button-side::before {
    position: absolute;
    bottom: 10em;
    left: 28em;
    width: 80px;
    font-weight: bold;
    content: "Siguiente";
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.3);
    color: #cbcbcb;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ff342f;
  }
}
@media (max-width: 290px) {
  .next-button-side::before {
    position: absolute;
    bottom: 10em;
    left: 22em;
    width: 30px;
    font-weight: bold;
    content: "➔";
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.3);
    color: #cbcbcb;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #ff342f;
  }
}

.next-button-side:hover {
  cursor: pointer;
  color: #ff342f;
}

.vjs-big-play-button {
  border-radius: 100px !important;
}

.video-js .vjs-big-play-button {
  font-size: 3.5em !important;
  line-height: 1.9em !important;
  width: 2em !important;
  height: 2em !important;
}

.vjs-menu-button-popup .vjs-menu {
  display: none;
  position: absolute;
  bottom: 0;
  width: 10em;
  left: -3em;
  height: 0;
  margin-bottom: 0;
  border-top-color: rgba(43, 51, 63, 0.7);
  padding-bottom: 24px;
}
.vjs-has-started .vjs-control-bar {
  height: 5em;
  background: none;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.8), transparent);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2em 1em 5em;
  border-radius: 15px;
}
.vjs-progress-holder:hover {
  display: block;
}
.vjs-control-bar {
  background-color: rgba(0, 0, 0, 0) !important;
}
.vjs-control {
  height: 80%;
}
.video-container {
  position: relative;
}
.ad-overlay {
  position: absolute;
  bottom: 8em;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 5px;
}
.ad-overlay-content {
  font-size: 16px;
  color: #e4b32b;
  font-weight: bold;
  text-align: left;
}

.ads-presentation {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  bottom: 8em;
  width: 100%;
  right: 4em;
  font-size: 14px;
  border-radius: 6px;
}
.ads-presentation-button {
  border: 3px solid #747272 !important;
  color: #524e4e !important;
  border-radius: 6px;
  padding-right: 3px;
  padding-left: 3px;
  text-align: center;
  height: 35px;
  font-size: 16px;
  font-weight: 700;
}
.ads-presentation-button:hover {
  border: 3px solid #ff342f !important;
  text-align: center;
  font-weight: 700;
  color: #ff342f !important;
  background-color: transparent !important;
  opacity: 1;
}

/* botón de descarga */
.vjs-download-button .vjs-icon-placeholder {
  height: 24px;
  width: 24px;
  background-image: url("../utils/images/downloadIcon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}
.vjs-download-button:hover {
  filter: invert(0.4) sepia(1) saturate(4695) hue-rotate(365deg);
}
@media (min-width: 375px) {
  .vjs-download-button .vjs-icon-placeholder {
    height: 24px;
    width: 24px;
    background-image: url("../utils/images/downloadIcon.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
    z-index: 1;
  }
  .vjs-download-button:hover {
    filter: invert(0.4) sepia(1) saturate(4695) hue-rotate(365deg);
  }
}
/* Playback rate */

.vjs-playback-rate .vjs-playback-rate-value {
  display: none;
}

.vjs-playback-rate > .vjs-menu-button,
.vjs-playback-rate .vjs-playback-rate-value {
  background-image: url("../utils/images/playbackrateIcon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  left: 1em !important;
  top: 9px !important;
}

.vjs-playback-rate > .vjs-menu-button:hover {
  filter: invert(0.4) sepia(1) saturate(4695) hue-rotate(365deg);
}

.live-icon-display {
  background-image: url("../utils/images/SportProTVIcon.gif");
  background-size: contain;
  width: 90px;
  height: 90px;
  display: block;
  position: absolute;
  right: 1em;
  top:1px;
}


.vjs-live-display {
  height: 10px;
  width: 10px;
  background-color: #ff342f;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  top: 0;
  border-radius: 20px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
