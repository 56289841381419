.rs-slider-bar {
  background-color: #380307 !important;
}
.rs-slider-progress-bar {
  background-color: #cc2500;
}
.rs-slider-handle:before {
  border: 2px solid #cc2500 !important;
}
.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: #cc2500 !important;
}
.rs-input-number-touchspin-down > .rs-icon,
.rs-input-number-touchspin-up > .rs-icon {
  background-color: #181514;
}

.rs-slider-handle:before {
  width: 20px;
  height: 20px;
  bottom: -15px;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-slider
  .ant-slider-handle::after {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  background-color: #ff0000 !important;
  box-shadow: 0 0 2px #3a130a !important;
  top: -5px !important;
}
