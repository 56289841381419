.CarouselContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.CarouselContent {
  width: 100%;
  height: 250px;
  background: #ccc;
  overflow: hidden;
}

.CarouselContent img {
  width: 100%;
  height: 250px;
}
.CarouselContainerBanner {
  width: auto;
  height: auto;
  background: #ccc;
  overflow: hidden;
}

.CarouselContainerBanner img {
  width: 100%;
  height: auto;
}
.BtwVideosContainer{
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}
.BtwVideosContainer img{
  width: 100%;
  height: auto;
  object-fit: scale-down;
  object-position: center center;

}
.MainBannerContainer{
  width: 100%;
  height: 20%;
  overflow: hidden;
  border-radius: 5px;
}
.MainBannerContainer img{
  width: 100%;
  height: 20%;
  object-fit: contain;
  object-position: center center;

}
.BottomVideoContainer{
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center center;
}

.BottomVideoContainer img{
  width:100%;
  object-fit: contain;
  object-position: center center;
}

@supports (object-fit: cover) {
  .CarouselContainer img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
@supports (object-fit: contain) {
  .MainBannerContainer img {
    height: 20%;
    object-fit: contain;
    object-position: center center;
  }
}
@supports (object-fit: scale-down) {
  .BtwVideosContainer img {
    height: 100%;
    object-fit: scale-down;
    object-position: center center;
    border-radius: 10px;
  }
}
@supports (object-fit: scale-down) {
  .BottomVideoContainer img {
    height: 100%;
    object-fit: scale-down;
    object-position: center center;
    border-radius: 10px;
  }
}
@media (max-width: 375px) {
  .CarouselContainer img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }

  @media (min-width: 1000px){
    .BottomVideoContainer img {
      width: calc(100% - 50px);
      height: auto;
      object-fit: fill;
      object-position: center;
      border-radius: 10px;
    }
  }
}
@media (min-width: 1200px) {
  .MainBannerContainer img {
    width:calc(100% - 150px);
    height:auto;
    object-fit: fill;
    object-position: center;
    margin-left:5%;
    border-radius: 10px;
  }
  .BottomVideoContainer img {
    width: calc(100% - 100px);
    height: auto;
    object-fit: fill;
    object-position: center;
    border-radius: 10px;
    margin-left:8%;
  }
}
@media (min-width: 1300px) {
  .MainBannerContainer img {
    width:calc(100% - 150px);
    height:auto;
    object-fit:fill;
    object-position:center;
    margin-left:5%;
    margin-right: 5%;
    border-radius: 10px;
  }
  .BottomVideoContainer img {
    width: calc(100% - 250px);
    height: auto;
    object-fit: fill;
    object-position: center;
    border-radius: 10px;
    margin-left:14%;
  }
}

@media (min-width: 1500px){
  .MainBannerContainer img {
    width:calc(100% - 350px);
    height:'auto';
    object-fit:fill;
    object-position:center center;
    margin-left:10%;
    margin-right: 10%;
    border-radius: 10px;
  }
}
