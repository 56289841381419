@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

body {
  margin: 0;
  font-family: "Twemoji Country Flags", "Roboto", Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link {
  text-decoration: none;
}

.offcanvas-body {
  padding: 0% !important;
}

.rs-sidenav-default {
  background-color: transparent !important;
}

.rs-sidenav-item {
  background-color: transparent !important;
}

.rs-sidenav-item:hover {
  background-color: rgb(241, 199, 199) !important;
}

.rs-dropdown-item {
  background-color: transparent !important;
}

.rs-dropdown-item:hover {
  background-color: transparent !important;
  background-color: rgb(241, 199, 199) !important;

}
.rs-btn-primary{
  background-color: black !important;
  border:none;
  color:#fff !important;
  font-weight:600 !important;
  font-size:12px !important;
}
.rs-btn-primary:hover{
  background-color: #fff !important;
  border:1px solid #181C32;
  color:#181C32 !important;

}
.rs-calendar-table-cell-selected {
  background-color: black !important;
  border:none;
  color:#fff !important;
  font-weight:600 !important;
  font-size:12px !important;
  border-radius:6px;
}

.iconsidenav {
  color: rgb(173, 173, 173);
  margin-right: 25px;
}

.iconfechasidenav {
  color: rgb(173, 173, 173);
  margin-right: 5px;
}

.itemsidenav:hover .iconsidenav {
  color: red
}

.itemsidenav {
  display: flex;
  justify-content: space-between;
}

.rs-sidenav-nav>.rs-sidenav-item {
  padding-left: 10px !important;
}



.labelsidenav {
  color: black
}


.rs-sidenav-nav>.rs-dropdown .rs-dropdown-toggle,
.rs-sidenav-nav>.rs-sidenav-item {
  padding-left: 10px !important;
}

.circular--square {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: fill;
  overflow: hidden;
}

.labelsdatauser {
  flex-direction: column;
  display: flex;
  align-items: center
}

.labeluser {
  font-weight: bold;
}

.labelemail {
  font-size: 15px;
}

.logocenter {
  margin-left: 30%;
  margin-right: 25%;
}

.footer {
  padding: 0.0 0;
  text-align: center;
  display: flex;
  background-color: black;
  color: white;
}

.bodycard {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

.bodycarddata {
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-left: 10px;
}

.bodycarddatatitle {
  font-weight: bold;
  width: 22em;
}

.bodycarddatacd {
  display: flex;
  flex-direction: column;
}

.views {
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: white;
  border-radius: 15px;
  background: rgba(0, 0, 0, .5);
  z-index: 20;
  line-height: 1.1;
  font-size: 12px;
  padding: 5px 12px;
  text-transform: uppercase;
  color: #fff;
  z-index: 0;
}

.likes {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  border-radius: 15px;
  background: rgba(0, 0, 0, .5);
  z-index: 20;
  line-height: 1.1;
  font-size: 12px;
  padding: 5px 12px;
  text-transform: uppercase;
  color: #fff;
  z-index: 0;

}

.play {
  position: absolute;
  bottom: 43%;
  right: 43%;
  color: white;
  border-radius: 10px;
  background: rgba(0, 0, 0, .5);
  z-index: 20;
  line-height: 1.1;
  font-size: 30px;
  padding: 5px 12px;
  text-transform: uppercase;
  color: #fff;
  z-index: 0;

}

.play:hover {
  color: #999;
  cursor: pointer;
}

.time {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
  border-radius: 15px;
  background: rgba(0, 0, 0, .5);
  z-index: 20;
  line-height: 1.1;
  font-size: 12px;
  padding: 5px 12px;
  text-transform: uppercase;
  color: #fff;
  z-index: 0;

}

.status {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  border-radius: 15px;
  background: rgba(0, 0, 0, .5);
  z-index: 20;
  line-height: 1.1;
  font-size: 12px;
  padding: 5px 12px;
  text-transform: uppercase;
  color: #fff;
  z-index: 0;

}

.live {
  background-color: rgb(234, 10, 10);
}

.stream {
  background-color: rgb(5, 109, 5);
}

.highlight{
  background-color: rgb(209, 184, 0);

}

.bodypage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.main {
  background: #fff;
  position: relative;
  z-index: 3;
  min-height: 100vh;

}

.cursortrue {
  cursor: pointer;
}
.cursortrue:hover{
  color: #009EF7
}

/* ocultar .iniciarsesion pantalla movil*/
@media (max-width: 768px) {
  .iniciarsesion {
    display: none !important;
  }
}

.iniciarsesion {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #181C32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.iniciarsesion:hover{
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #009EF7;
}

.cssload-loader {
	position: relative;
	left: calc(50% - 44px);
	width: 88px;
	height: 88px;
	border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
	perspective: 1100px;
}

.cssload-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;	
}

.cssload-inner.cssload-one {
	left: 0%;
	top: 0%;
	animation: cssload-rotate-one 0.85s linear infinite;
		-o-animation: cssload-rotate-one 0.85s linear infinite;
		-ms-animation: cssload-rotate-one 0.85s linear infinite;
		-webkit-animation: cssload-rotate-one 0.85s linear infinite;
		-moz-animation: cssload-rotate-one 0.85s linear infinite;
	border-bottom: 4px solid rgba(153,50,153,0.98);
}

.cssload-inner.cssload-two {
	right: 0%;
	top: 0%;
	animation: cssload-rotate-two 0.85s linear infinite;
		-o-animation: cssload-rotate-two 0.85s linear infinite;
		-ms-animation: cssload-rotate-two 0.85s linear infinite;
		-webkit-animation: cssload-rotate-two 0.85s linear infinite;
		-moz-animation: cssload-rotate-two 0.85s linear infinite;
	border-right: 4px solid rgb(102,184,55);
}

.cssload-inner.cssload-three {
	right: 0%;
	bottom: 0%;
	animation: cssload-rotate-three 0.85s linear infinite;
		-o-animation: cssload-rotate-three 0.85s linear infinite;
		-ms-animation: cssload-rotate-three 0.85s linear infinite;
		-webkit-animation: cssload-rotate-three 0.85s linear infinite;
		-moz-animation: cssload-rotate-three 0.85s linear infinite;
	border-top: 4px solid rgb(191,23,23);
}







@keyframes cssload-rotate-one {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@-o-keyframes cssload-rotate-one {
	0% {
		-o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@-ms-keyframes cssload-rotate-one {
	0% {
		-ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@-webkit-keyframes cssload-rotate-one {
	0% {
		-webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@-moz-keyframes cssload-rotate-one {
	0% {
		-moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		-moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@keyframes cssload-rotate-two {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@-o-keyframes cssload-rotate-two {
	0% {
		-o-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-o-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@-ms-keyframes cssload-rotate-two {
	0% {
		-ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@-webkit-keyframes cssload-rotate-two {
	0% {
		-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@-moz-keyframes cssload-rotate-two {
	0% {
		-moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		-moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@keyframes cssload-rotate-three {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@-o-keyframes cssload-rotate-three {
	0% {
		-o-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-o-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@-ms-keyframes cssload-rotate-three {
	0% {
		-ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@-webkit-keyframes cssload-rotate-three {
	0% {
		-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@-moz-keyframes cssload-rotate-three {
	0% {
		-moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		-moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}