*, *::before{box-sizing: border-box;}

.whatsappbubble{
    position: fixed;
    right: 0;
    margin-right: 7em;
    margin-top: 80vh;
    width: 10px;
    z-index: 999;
    
}

.whatsappbutton{
    background-color: transparent;
    border-radius: 50%;
}

.faketooltip{
    position: fixed;
    right: 0;
    margin-right: 8em;
    margin-top: 81vh;

    background-color: rgba(69, 71, 69, 0.8);
    width: 150;
    height: 50px;
    z-index: 999;
    border-radius: 5px;
}

.tttext{
    color: white;
    font-size: 15px;
    text-align: center;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}



