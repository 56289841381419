.tablausuarios{
    width: 270px;
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.tablaLives{
    width: 700px!important;
    height: 200px;
    
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: scroll;
}

.htitle{
    text-align: center;
}

.hlives{
    text-align: center;
    margin-top: -10px;
}
.hlivescount{
    text-align: center;
    margin-top: 5px;
}

.cardCounts{
    border-radius: 15%;
    width: 220px;
    text-align: center;
}

.cardtext{
    display: flex;
    justify-content: space-around;
}


th, td{
    text-align: center;
}

.cursortrue {
    cursor: pointer;
  }
  .cursortrue:hover{
    color: #009EF7
  }