.becomingpro{
    background-color: transparent;
    border-radius: 0.25rem;
    color: black;
    cursor: pointer;
}

.becomingpro:hover{
    color: black;
}

.becomingpro:focus:not(:focus-visible){
    color: black;
    background-color: #9a0101;
}