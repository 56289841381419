.eventConfig{
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    background-color: #F2F2F2;
    height: 40px;
    padding: 0 20px;
    margin-right: 30px;
}
.mrgl{
    margin-left: 30px;
}
.editar:hover{
    background-color: rgb(56, 237, 56);
    color: white;
}
.eliminar:hover{
    background-color: red;
    color: white;
}

.eventicon{
    margin-right: 10px;
}

.bannerTitle{
    height: 30em;
    width: 100%;
    /* object-fit: cover; */
    /* object-position: 30% 40%; */
    background-color: green;
}
.bannerImage{
    height: 30em;
    width: 100%;
    background-position: 50%;
    background-size: cover;
    background-color: green;
}

.titleEvent{
    padding-top: 10%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 6vh;
    font-weight: bold;
    color: white;
    text-align: center;
    text-shadow: 1px 1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000,
    0px 1px 0 #000,
    0px -1px 0 #000,
    -1px 0px 0 #000,
    1px 0px 0 #000,
    2px 2px 0 #000,
    -2px 2px 0 #000,
    2px -2px 0 #000,
    -2px -2px 0 #000,
    0px 2px 0 #000,
    0px -2px 0 #000,
    -2px 0px 0 #000,
    2px 0px 0 #000,
    1px 2px 0 #000,
    -1px 2px 0 #000,
    1px -2px 0 #000,
    -1px -2px 0 #000,
    2px 1px 0 #000,
    -2px 1px 0 #000,
    2px -1px 0 #000,
    -2px -1px 0 #000;
}

.rs-picker-menu{
    z-index: 9999!important;

}

.modalBody{
    overflow: auto;
}

@media screen and (max-width: 480px) {
    .titleEvent{
        padding-top: 10%;
        padding-left: 10%;
        padding-right: 10%;
        font-size: 5vh;
        font-weight: bold;
        color: white;
        text-align: center;
        border-color: black;
        border-width: 2px;
    }
  }