.no-border {
    box-shadow: 0px 4px 10px rgba(214, 53, 53, 0.15); /* Sombra elegante */
    display: flex; /* Centra el contenido dentro */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    width: 160px; /* Tamaño del contenedor */
    height: 160px;
  }
  .no-border img {
    border-radius: 50%; /* Asegura que la imagen también sea redonda */
    object-fit: cover; /* Recorta la imagen correctamente */
    width: 100%; /* Ajusta el tamaño de la imagen al contenedor */
    height: 100%;
  }
  